<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title">{{courseName}}
      <div class="hk_page_title_right" v-if="state==2">
        <span class="hk-font-grey hk-fontsize12" v-if="sltPhoneArr.length">共选中 <span class="hk-font-highlight hk-fontsize14 hk-font-bold">{{sltPhoneArr.length}}</span> 人&nbsp;&nbsp;</span>
        <el-select style="width:260px;" size="small" v-model="sltPhoneArr" clearable multiple filterable collapse-tags placeholder="筛选学员">
          <el-option :label="item.stuName+''+item.stuPhone" :value="idx" :key="idx" v-for="(item,idx) in termList">{{ item.stuName }}--{{item.stuPhone}}</el-option>
        </el-select>
        &nbsp;<el-button type="primary" plain size="small" @click="searchFun">查询报告</el-button>
      </div>
    </div>
    <div class="hk_page_wrap">
      <div class="hk-common-page">
        <div class="section-main">
          <div class="template-main" v-if="state == 1 || state == 3">
            <h3 class="fmt-title">生成设置</h3>
            <el-form size="small" :rules="fmtRules" ref="fmtForm" :model="fmtForm">
              <el-form-item label="选择生成讲次：" prop="endIdx">
                <el-select class="select-half" v-model="fmtForm.beginIdx" placeholder="开始讲次">
                  <el-option v-for="item in lessonTotalNum" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
                ——
                <el-select class="select-half" v-model="fmtForm.endIdx" placeholder="结束讲次">
                  <el-option v-for="item in lessonTotalNum" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="生成格式：" prop="fomartNum">
                <el-radio-group v-model="fmtForm.fomartNum" v-removeAriaHidden>
                  <el-radio label="1">格式一</el-radio>
                  <el-radio label="2">格式二</el-radio>
                </el-radio-group>
                <div class="el-form-item__error" style="padding-left: 94px">
                  格式样式仅供参考，以导出实际样式为准
                </div>
              </el-form-item>
              <el-form-item label="选择颜色：" prop="tableBorderColor">
                <el-color-picker v-model="fmtForm.tableBorderColor"></el-color-picker>
              </el-form-item>
              <el-form-item label="每页个数：">
                <el-select v-model="pageSize" placeholder="请选择" style="width:80px">
                  <el-option label="10" value="10"></el-option>
                  <el-option label="20" value="20"></el-option>
                  <el-option label="30" value="30"></el-option>
                  <el-option label="50" value="50"></el-option>
                </el-select>
                <div class="el-form-item__error" style="padding-left: 84px">
                  若电脑配置不高，请选择10或20，避免出现无法下载报告的情况
                </div>
              </el-form-item>
            </el-form>
            <div class="fmt-list">
              <!-- 
              <img class="fmt-item" src="https://oss.ke.live/images/fm1.jpg" />
              <img class="fmt-item" src="https://oss.ke.live/images/fm2.jpg" />-->
              <div class="term-table">
                <table>
                  <tr class="tr-title">
                    <td colspan="8">
                      班级名称动态生成仅供参考样式，以实际生成为准（格式一）
                    </td>
                  </tr>
                  <tr class="tr-sec-title">
                    <td rowspan="2" width="200">课节名称</td>
                    <td colspan="3">学习时长（min）</td>
                    <td colspan="3">课堂参与</td>
                    <td rowspan="2">作业</td>
                  </tr>
                  <tr class="tr-third-title">
                    <td>课程时长</td>
                    <td>直播</td>
                    <td>回放</td>
                    <td>总次数</td>
                    <td>正确</td>
                    <td>奖励</td>
                  </tr>
                  <tbody>
                    <tr>
                      <td>demo这里是课节名称</td>
                      <td>120</td>
                      <td>120</td>
                      <td>100</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>未提交</td>
                    </tr>
                  </tbody>
                </table>
                <div class="term-desc">格式一</div>
              </div>
              <div class="term-table">
                <table>
                  <tr class="tr-title">
                    <td colspan="7">
                      班级名称动态生成（格式二）
                    </td>
                  </tr>
                  <tr class="tr-sec-title">
                    <td rowspan="2" width="200">课节名称</td>
                    <td colspan="3">学习时长（min）</td>
                    <td colspan="2">课堂参与</td>
                    <td rowspan="2">作业</td>
                  </tr>
                  <tr class="tr-third-title">
                    <td>课程时长</td>
                    <td>直播</td>
                    <td>回放</td>
                    <td>举手</td>
                    <td>奖励</td>
                  </tr>
                  <tbody>
                    <tr>
                      <td>
                        课节名称demo数据：注意学习桌前的正确姿势
                      </td>
                      <td>120</td>
                      <td>120</td>
                      <td>100</td>
                      <td>0</td>
                      <td>0</td>
                      <td>未提交</td>
                    </tr>
                  </tbody>
                </table>
                <div class="term-desc">格式二</div>
              </div>
            </div>
          </div>

          <!-- 生成图片 -->
          <div class="template-main" v-if="state == 2">
            <div class="export-tabs" v-if="studentTotal > pageSize">
              分页切换：
              <el-radio-group v-model="pageSelect" size="mini">
                <el-radio-button :label="num" v-for="num in studentTotalPage" :key="num">{{ (num - 1) * pageSize + 1 }}-{{
                    num * pageSize
                  }}</el-radio-button>
              </el-radio-group>
            </div>
            <div class="export-main">
              <div class="export-item" v-for="item in curPageTerm" :key="item.id">
                <div class="export-table term-table j_export_table" :username="item.stuName+'_'+item.stuPhone">
                  <template v-if="fmtForm.fomartNum == '1'">
                    <!-- 格式一 -->
                    <table>
                      <tr class="tr-title">
                        <td colspan="8">
                          {{courseName}}
                        </td>
                      </tr>
                      <tr class="tr-sec-title">
                        <td rowspan="2" width="200">课节名称</td>
                        <td colspan="3">学习时长（min）</td>
                        <td colspan="3">课堂参与</td>
                        <td rowspan="2">作业</td>
                      </tr>
                      <tr class="tr-third-title">
                        <td>课程时长</td>
                        <td>直播</td>
                        <td>回放</td>
                        <td>总次数</td>
                        <td>正确</td>
                        <td>奖励</td>
                      </tr>
                      <tbody>
                        <tr v-for="(lessonItem,lessonIdx) in item.classStudentList" :key="lessonIdx+'_'+lessonItem.id">
                          <td>
                            <span v-html="filterClassName(lessonItem.className)"></span>

                            <!-- {{lessonItem.className}} -->
                          </td>
                          <td>{{lessonItem.videoPlaybackMin || '/'}}</td>
                          <td>{{lessonItem.attendMin || '/'}}</td>
                          <td>{{lessonItem.stuPlaybackMin || '/'}}</td>
                          <td>{{lessonItem.answerCount || '/'}}</td>
                          <td>{{lessonItem.answerCorrectCount || '/'}}</td>
                          <td>{{lessonItem.awardCount || '/'}}</td>
                          <td>{{lessonItem.homeworkStatus || '未提交'}}</td>
                        </tr>

                      </tbody>
                    </table>
                  </template>
                  <template v-else>
                    <!-- 格式二 -->
                    <table>
                      <tr class="tr-title">
                        <td colspan="7">
                          {{courseName}}
                        </td>
                      </tr>
                      <tr class="tr-sec-title">
                        <td rowspan="2" width="200">课节名称</td>
                        <td colspan="3">学习时长（min）</td>
                        <td colspan="2">课堂参与</td>
                        <td rowspan="2">作业</td>
                      </tr>
                      <tr class="tr-third-title">
                        <td>课程时长</td>
                        <td>直播</td>
                        <td>回放</td>
                        <td>举手</td>
                        <td>奖励</td>
                      </tr>
                      <tbody>
                        <tr v-for="(lessonItem,lessonIdx) in item.classStudentList" :key="lessonIdx+'_'+lessonItem.id">
                          <td>
                            <span v-html="filterClassName(lessonItem.className)"></span>

                          </td>
                          <td>{{lessonItem.videoPlaybackMin || '/'}}</td>
                          <td>{{lessonItem.attendMin || '/'}}</td>
                          <td>{{lessonItem.stuPlaybackMin || '/'}}</td>
                          <td>{{lessonItem.handsUpCount || '/'}}</td>
                          <td>{{lessonItem.awardCount || '/'}}</td>
                          <td>{{lessonItem.homeworkStatus || '未提交'}}</td>
                        </tr>

                      </tbody>
                    </table>
                  </template>

                </div>
                <div class="export-name">
                  {{ item.stuName }}--{{item.stuPhone}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hk-common-footer">
      <el-button size="middle" @click.stop="cancelConfigFun" v-if="state == 3">取消</el-button>
      <el-button size="middle" type="primary" @click.stop="saveConfigFun('fmtForm')" v-if="state == 1 || state == 3">生成</el-button>
      <el-button size="middle" @click.stop="saveAgainFun" v-if="state == 2">重新生成</el-button>
      <el-button size="middle" type="primary" @click.stop="saveExportImgFun" v-if="state == 2" v-loading.fullscreen.lock="pageLoading">导出{{
          studentTotalPage > 1 ? "第" + pageSelect + "页" : ""
        }}</el-button>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import JSZip from "jszip";
import FileSaver from "file-saver";
export default {
  data() {
    return {
      courseId: "", //课程id
      courseName: '',//课程名次
      subjectStr: '', //学科，英语学科的课节名格式要变一下

      state: 1, //1--第一次进入页面，不显示“取消”；2--进入导出和重新生成；3--点击重新生成后，显示“取消”
      fmtForm: {
        beginIdx: "1",
        endIdx: "", //
        tableBorderColor: '#c3e79b', //表格中border的颜色  #c3e79b
        fomartNum: "1", //默认格式1
      },
      lessonTotalNum: 15, //总共几节课，默认15，实际是动态获取的
      fmtRules: {
        endIdx: [
          { required: true, message: "请选择讲次", trigger: "change" },
        ],
        tableBorderColor: [{ required: true, message: "请选择", trigger: "blur" }],
        fomartNum: [{ required: true, message: "请选择", trigger: "blur" }],
      },

      sltBorderColor: '',

      finalCanvas: [], //最终生成的canvas集合
      studentTotal: 0, //总共要生成多少人的报告
      studentTotalPage: 1, //总共分几页
      pageSize: 50, //每页50条数据
      pageSelect: "1", //选择的页码
      termList: [], //所有学员的报告信息
      curPageTerm: [], //当前分页的数据，根据pageSelect变化
      pageLoading: false, //true-页面加载

      sltPhoneArr: [], //顶部下拉框选中的学员列表
    };
  },
  watch: {
    'fmtForm.tableBorderColor': {
      handler(val, old) {
        this.sltBorderColor = val
      }, immediate: true,
      deep: true,
    },
    pageSelect: {
      handler(val, old) {
        let tmpPageSelect = parseInt(val);
        console.log(tmpPageSelect, "-----第几页了");
        let tmpPageData = [];
        if (tmpPageSelect == this.studentTotalPage) {
          //最后一页，可能没有那么多数据
          console.log("-----最后一页");
          tmpPageData = this.termList.slice(
            (tmpPageSelect - 1) * this.pageSize
          );
        } else {
          //slice, start end（不包含end）。没有end则表示到最后一个，不改变原数组
          tmpPageData = this.termList.slice(
            (tmpPageSelect - 1) * this.pageSize,
            tmpPageSelect * this.pageSize
          );
        }
        console.log(tmpPageData, "-----tmpPageData");
        this.curPageTerm = [];
        tmpPageData.forEach((item) => {
          this.curPageTerm.push(item);
        });
        console.log(this.curPageTerm, "---curpageterm");
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    let that = this;
    let pageQuery = that.$route.query
    if (pageQuery.id) {
      that.courseId = parseInt(pageQuery.id)
    }
    if (pageQuery.name) {
      that.courseName = decodeURIComponent(pageQuery.name)
    }
    if (pageQuery.subject) {
      that.subjectStr = decodeURIComponent(pageQuery.subject)
    }

  },
  methods: {
    filterClassName(name) {
      let that = this;
      let txt = name
      console.log(txt, '------判断之前名次')
      if (that.subjectStr == '英语') {
        let splitIdx = name.indexOf('讲') //第一个“讲”的索引
        txt = name.substr(0, splitIdx + 1) + '<br>' + name.substr(splitIdx + 1)

        console.log(txt, '------英语 名次')
      }
      return txt


    },
    searchFun() {
      let that = this;
      if (that.sltPhoneArr.length) {
        that.studentTotal = that.sltPhoneArr.length //总数据

        let tmpPage = parseInt(that.studentTotal / that.pageSize);
        if (that.studentTotal % that.pageSize) {
          tmpPage = tmpPage + 1;
        }
        that.studentTotalPage = tmpPage;

        let tmpPageTerm = []
        that.sltPhoneArr.forEach(itemIdx => {
          tmpPageTerm.push(that.termList[parseInt(itemIdx)])
        })
        that.curPageTerm = tmpPageTerm;
        console.log(that.curPageTerm, "---搜索---totalPage--", that.studentTotalPage);
        that.$message.success('查询成功')

      } else {
        //显示全部
        that.getDataList()

      }

    },

    //获取数据列表
    getDataList() {
      let that = this;
      let params = {
        courseId: that.courseId,
        beginIdx: parseInt(that.fmtForm.beginIdx),
        endIdx: parseInt(that.fmtForm.endIdx)
      };
      that.request({
        method: "post",
        url: "/manage/class/listReport",
        param: params,
        success: function (res) {
          if (res.code == 200) {
            that.termList = res.result || []
            that.studentTotal = that.termList.length //总数据

            let tmpPage = parseInt(that.studentTotal / that.pageSize);
            if (that.studentTotal % that.pageSize) {
              tmpPage = tmpPage + 1;
            }
            that.studentTotalPage = tmpPage;

            that.curPageTerm = that.termList.slice(0, that.pageSize);
            console.log(that.curPageTerm.length, "---当前页数据个数--接口获取---totalPage--", that.studentTotalPage);

          } else {
            that.$message.error("获取失败--" + res.msg);
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error("获取异常");
        },
      });
    },

    //生成，先校验表格
    saveConfigFun(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.getDataList();
          that.state = 2;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //导出
    saveExportImgFun() {
      let that = this;
      //loading
      that.pageLoading = true;
      that.finalCanvas = []; //没有导出的时候，数据要先清空下

      const elements = document.querySelectorAll(".j_export_table"); // 获取需要导出的元素集合

      let totalEle = elements.length;
      elements.forEach((element, index) => {
        let tmpName = element.getAttribute("username");
        html2canvas(element, {
          backgroundColor: null,
          scale: 3,
        })
          .then((canvas) => {
            let dataURL = canvas.toDataURL("image/png");
            that.finalCanvas.push({
              fileUrl: dataURL,
              renameFileName: tmpName + ".png", //重命名
            });

            if (index == totalEle - 1) {
              //最后一个
              that.filesToRar();

            }
          })
          .catch((err) => {
            console.log(err, "----循环出错");
            setTimeout(() => {
              that.pageLoading = false;
            }, 2500);
          });
      }); //end foreach
    },

    //打包
    filesToRar() {
      let that = this;
      let zip = new JSZip();
      let cache = {};
      let promises = [];

      var arrImages = that.finalCanvas;
      // console.log(arrImages)
      for (let item of arrImages) {
        const promise = that.getImgArrayBuffer(item.fileUrl).then((data) => {
          // 下载文件, 并存成ArrayBuffer对象(blob)
          zip.file(item.renameFileName, data, {
            binary: true,
          }); // 逐个添加文件
          // zip.file(item.renameFileName, data, {base64: true}); // 逐个添加文件

          cache[item.renameFileName] = data;
        });
        promises.push(promise);
      }

      Promise.all(promises)
        .then(() => {
          zip
            .generateAsync({
              type: "blob",
            })
            .then((content) => {
              // 生成二进制流
              let zipName = that.courseId + "_期中报告" + that.pageSelect;
              FileSaver.saveAs(content, zipName); // 利用file-saver保存文件  自定义文件名
              setTimeout(() => {
                that.$message({
                  message: '导出成功',
                  type: 'success'
                });
                that.pageLoading = false;
              }, 2500);
            });
        })
        .catch((res) => {
          console.log(res, '---压缩失败catch方法')
          that.$message.error("文件压缩失败");
          setTimeout(() => {
            that.pageLoading = false;
          }, 2500);
        });
    },

    //获取文件blob
    getImgArrayBuffer(url) {
      let that = this;
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", url, true);
        xmlhttp.responseType = "blob";
        xmlhttp.onload = function () {
          if (this.status == 200) {
            resolve(this.response);
          } else {
            reject(this.status);
          }
        };
        xmlhttp.send();
      });
    },

    //重新生成
    saveAgainFun() {
      let that = this;
      that.state = 3;
    },

    //取消，回到“重新生成”
    cancelConfigFun() {
      let that = this;
      that.state = 2;
    },

  },
};
</script>

<style lang="scss" scoped>
.hk_page_wrap {
  padding-bottom: 60px;

  .section-main {
    font-size: 16px;
    font-weight: 400;
    color: #606060;
    line-height: 22px;

    .template-main {
      .fmt-title {
        font-weight: 600;
        font-size: 16px;
        color: #111111;
        line-height: 24px;
        padding-bottom: 12px;
      }
      .select-half {
        width: 100px !important;
      }
      ::v-deep .el-form-item__error {
        padding-left: 120px; //仅适用于“请选择讲次”的错误提示信息
      }
      .fmt-list {
        max-width: 570px;
        padding-top: 16px;
        .fmt-item {
          height: 120px;
          display: block;
          margin-bottom: 10px;
        }
      }

      //表格分页
      .export-tabs {
        font-weight: 400;
        font-size: 13px;
        color: #333333;
        line-height: 20px;
        padding-bottom: 18px;
      }

      //表格
      .export-main {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: space-between;

        .export-item {
          width: 570px;
          margin: 0 0 10px 0;
          border-radius: 4px;
          border: 1px solid #dcdcdc;
          padding: 8px;

          .export-name {
            padding-top: 5px;
            font-weight: 400;
            font-size: 13px;
            color: #9e9e9e;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }

  //公用的table样式
  .term-table {
    background: transparent;

    table {
      width: 100%;
      border-collapse: collapse;
      padding: 2px;
      border-top: 1px solid v-bind(sltBorderColor);
      border-left: 1px solid v-bind(sltBorderColor);
      // border: 1px solid v-bind(sltBorderColor);

      font-weight: 400;
      color: #333;
      line-height: 22px;

      td {
        vertical-align: middle;
        padding: 4px 6px;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        border-right: 1px solid v-bind(sltBorderColor);
        border-bottom: 1px solid v-bind(sltBorderColor);

        &:first-child {
          letter-spacing: 1px;
          // text-align: left;
          // text-align: justify;
          // word-wrap: break-word;
        }
        // &:last-child {
        //   border-right: 0;
        // }
      }
      .tr-title {
        border-bottom: 1px solid v-bind(sltBorderColor);

        td {
          text-align: center;
          font-weight: 600;
          font-size: 16px;
          padding: 12px 2px;
        }
      }
      .tr-sec-title {
        td {
          text-align: center;
          font-size: 14px;
          font-weight: 600;
        }
        .tr-third-title {
          td {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
    .term-desc {
      font-size: 12px;
      color: #666;
      line-height: 20px;
      text-align: center;
      padding: 0 0 16px;
    }
  }
}
</style>